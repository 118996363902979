// components/ProtectedRoute.tsx
"use client";

import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import React, { useEffect } from "react";
import SpinnerLoading from "../components/loading/Spinner";

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { data: session, status } = useSession();
  const router = useRouter();

  useEffect(() => {
    if (status === "loading") return; // Do nothing while loading

    if (session) {
      router.push("/dashboard");
    }
  }, [session, status, router]);

  if (status === "loading" || session) {
    // Optionally show a loading spinner or nothing while checking session
    return <SpinnerLoading />;
  }

  return <>{children}</>; // Render the children if no session exists
};

export default ProtectedRoute;
