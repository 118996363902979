"use client";
import axios from "axios";
import { useRouter } from "next/navigation";
import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { FcGoogle } from "react-icons/fc";
import { toast } from "react-toastify";
import { signIn, signOut } from "next-auth/react";

interface FormData {
  email: string;
  password: string;
  rememberMe?: boolean;
}

const LoginForm: React.FC = () => {
  const [defaultEmail, setDefaultEmail] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const router = useRouter();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const result = await signIn("credentials", {
        redirect: false,
        email: data.email,
        password: data.password,
      });

      if (result?.error) {
        toast.error(result.error);
      } else {
        toast.success("Login successful!");
        if (data.rememberMe) {
          localStorage.setItem("rememberedEmail", data.email);
        } else {
          localStorage.removeItem("rememberedEmail");
        }

        // Redirect after successful login
        router.push("/dashboard"); // Redirect to a protected page
      }
    } catch (error: any) {
      toast.error("An error occurred during login.");
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signIn("google");

      // signOut();
    } catch (error) {
      console.error("Google Sign-In Error:", error);
      toast.error("An error occurred during Google sign-in.");
    }
  };

  useEffect(() => {
    // Akses localStorage hanya di klien
    const rememberedEmail = localStorage.getItem("rememberedEmail") || "";
    setValue("email", rememberedEmail);
    setDefaultEmail(rememberedEmail);
  }, [setValue]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col bg-gray-100 w-full mt-40 rounded-2xl p-8 border-2 border-gray-400"
    >
      {/* Title */}
      <span className="text-3xl font-bold py-2">Welcome Back!</span>

      {/* Description */}
      <span className="text-sm text-justify">
        Hey there! Ready to log in? Just enter your username and password below
        and you&apos;ll be back in action in no time. Let&apos;s go!
      </span>

      {/* Button for Google Login */}
      <button
        onClick={handleGoogleSignIn}
        className="border-2 border-gray-200 bg-white text-font rounded-lg px-4 py-2 my-4 flex items-center justify-center"
      >
        <FcGoogle className="text-2xl mr-2" />
        <span>Continue with Google</span>
      </button>

      {/* Divider */}
      <div className="flex justify-center items-center">
        <hr className="bg-gray-400 w-1/2" />
        <span className="text-gray-400 font-semibold mx-2">Or</span>
        <hr className="bg-gray-400 w-1/2" />
      </div>

      {/* Email Input */}
      <div className="mb-4">
        <label htmlFor="email" className="font-bold">
          Email
        </label>
        <input
          type="email"
          id="email"
          {...register("email", { required: "Email is required" })}
          required
          placeholder="email"
          className="border-2 border-gray-200 bg-white text-font rounded-lg px-2 py-2 my-4 w-full"
        />
      </div>

      {/* Password Input */}
      <div className="mb-4">
        <label htmlFor="password" className="font-bold">
          Password
        </label>
        <input
          type="password"
          id="password"
          {...register("password", { required: "Password is required" })}
          required
          placeholder="Password"
          className="border-2 border-gray-200 bg-white text-font rounded-lg px-2 py-2 my-4 w-full"
        />
      </div>

      {/* Remember Me and Forgot Password */}
      <div className="flex justify-between items-center my-4">
        <div className="flex items-center gap-2">
          <input type="checkbox" id="rememberMe" {...register("rememberMe")} />
          <label htmlFor="rememberMe">Remember Me</label>
        </div>
        <a className="ml-4 text-primary underline text-end">Forgot Password?</a>
      </div>

      {/* Sign In Button */}
      <button
        type="submit"
        className="bg-primary text-font font-semibold rounded-2xl px-4 py-2"
      >
        Sign In
      </button>

      {/* Sign Up Link */}
      <span className="text-center mt-2 py-2">
        Don&apos;t have an account?
        <a href="/sign-up" className="pl-2 text-primary">
          Sign Up
        </a>
      </span>
    </form>
  );
};

export default LoginForm;
