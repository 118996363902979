"use client";
import React from "react";
import HeroSection from "../components/sections/hero";
import LoginForm from "../components/form/LoginForm";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "../contex/ProtectedRoute";
import "react-toastify/dist/ReactToastify.css";
import { bgLanding } from "../data/landing";

const LoginPage = () => {
  /**
   * JSX for the login form.
   */

  return (
    <ProtectedRoute>
      <div>
        <div className="flex flex-col items-center mt-10 mx-6 lg:mx-24 rounded-lg">
          {/* Hero Section */}
          <HeroSection
            url={bgLanding.background1ImageSrc}
            text="Become a Student"
            borderRadius=" rounded-2xl"
            height="h-[300px]"
            marginText="-mt-44"
          />

          <LoginForm />

          <ToastContainer className="mt-20 " />
        </div>
      </div>
    </ProtectedRoute>
  );
};
export default LoginPage;
