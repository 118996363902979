import React from "react";
import { FaSync } from "react-icons/fa"; // Import ikon yang diinginkan

const SpinnerLoading: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="relative flex items-center justify-center w-16 h-16">
        <div className="absolute w-16 h-16 border-4 border-t-4 border-primaryYellow border-solid rounded-full animate-spin"></div>
        <FaSync className="absolute text-primaryYellow text-4xl animate-spin" />
      </div>
    </div>
  );
};

export default SpinnerLoading;
